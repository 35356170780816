
.carousel-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.zoomed-in {
    transform: scale(1.2);
    transition: transform 0.5s ease-in-out;
}

.zoomed-out {
    transform: scale(1);
    transition: transform 0.5s ease-in-out;
}

.carousel {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 290px;
    padding-bottom: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
}

.carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: scale(0.8);
}

.carousel-item.active {
    opacity: 1;
    transform: scale(1);
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.caro-container {
    width: 100%;
    
    position: relative;
    margin-top: 20px;
}