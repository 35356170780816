

.carousel-wrapper .section-heading {
  text-align: center;
  margin-bottom: 40px;
}

.carousel-wrapper .section-heading h2 {
  font-size: 2rem;
  font-weight: bold;
}

.carousel-wrapper .section-heading .line-dec {
  width: 50px;
  height: 2px;
  background-color: #7f00ff;
  margin: 20px auto 0;
}

.owl-carousel .item img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.owl-carousel .down-content {
  padding: 20px 10px;
  text-align: center;
}

.owl-carousel .down-content h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.owl-carousel .main-button a {
  background-color: #7f00ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.owl-carousel .main-button a:hover {
  background-color: #5c00cc;
}

.carousel-wrapper .section-heading {
  text-align: center;
  margin-bottom: 40px;
}

.carousel-wrapper .section-heading h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.carousel-wrapper .section-heading .line-dec {
  width: 50px;
  height: 2px;
  background-color: #7f00ff;
  margin: 20px auto 0;
}



.client-logo-container {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0px;
  transition: transform 0.3s ease;
}

.client-logo-container:hover {
  transform: scale(1.05);
}

.client-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.cate{
  padding-left: 60px;
}

