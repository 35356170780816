.preloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff; /* You can change the background color as needed */
}

.preloader-logo {
  width: 100px; /* Adjust the size as needed */
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}







/* .js-preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color:  #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preloader-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    margin-bottom: 10px;
    animation: dot-pulse 1.5s infinite;
  }
  
  .dots span {
    width: 10px;
    height: 10px;
    background-color: #333;
    border-radius: 50%;
    margin: 5px;
    animation: dots-jump 1.5s infinite ease-in-out;
  }
  
  .dots span:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dots span:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  @keyframes dot-pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  @keyframes dots-jump {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }
   */

