
h1 {
    text-align: center;
    /* margin-bottom: 40px; */
    margin-top: 10px;
    font-size: 30px;
    letter-spacing:1px;
    line-height: 1.5;
    
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    line-height: 1.5;
    color: white;
  }
   li:before {
    /* content: "\2022"; */
    color: #fbfbfb;
    font-weight: bold;
    font-size: 1.2em;
    position: absolute;
    left: 0;
    top: 2px;
  }

  .sub-menu li{
    text-decoration: none;
  }

  .container1 {
        text-align: center;
        justify-content: center;
    }

    .containerf1 {
      text-align: center;
      justify-content: center;
  }

    .btn {
        
        display: inline-block;
    }

    .author-page .section-heading {
        margin-top: 10px;
    }

    .pnlm-author-box {
      display: none !important;
    }