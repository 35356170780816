/* ---------------------------------------------
TemplateMo 577 Liberty Market

https://templatemo.com/tm-577-liberty-market
--------------------------------------------- */

/* ---------------------------------------------
font & reset css
--------------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800,900");
@import url('https://fonts.googleapis.com/css2?family=Italianno&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Stylish&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&family=Lobster&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Stylish&display=swap');

/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  background-color: #1e1e1e;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: 700;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 30px;
  color: #fff;
}

img {
  width: 100%;
  overflow: hidden;
}

/* ---------------------------------------------
Global Styles
--------------------------------------------- */
html, body {
  background: #fff;
  font-family: 'Roboto', sans-serif;
}

::selection {
  background: #7453fc;
  color: #fff;
}

::-moz-selection {
  background: #7453fc;
  color: #fff;
}

.border-button a {
  font-size: 14px;
  color: #000000;
  background-color: transparent;
  border: 1px solid #7453fc;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.border-button a:after {
  width: 78%;
  height: 2px;
  background-color: #fff;
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.border-button a:hover {
  background-color: #7453fc;
  color: #fff;
}

.main-button a {
  font-size: 14px;
  color: #fff;
  background-color: #7453fc;
  border: 1px solid #7453fc;
  padding: 8px 8px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.main-button a:after {
  width: 78%;
  height: 2px;
  background-color: #fff;
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.main-button a:hover {
  background-color: #fff;
  color: #7453fc;
  border: 1px solid #fff;
}

section {
  margin-top: 120px;
}

.section-heading {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 50px;
  text-align: center;
}

.section-heading .line-dec {
  width: 100px;
  height: 2px;
  background-color: #7453fc;
  margin: 0 auto 30px auto;
}

.section-heading h2 {
  margin-top: 10px;
  line-height: 36px;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
}

.section-heading h2 em {
  color: #7453fc;
  font-style: normal;
}

/* ---------------------------------------------
Header
--------------------------------------------- */
/* Base styles for desktop */

/* Common styles for header */
.header-area {
    background-color: #ffffff;
    box-shadow:0 8px 15px -5px #7453fc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 90px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 0 20px; 
    
}


.header-area .main-nav .logo-container {
 
    height: 100px; /* Container height for larger logo */
    margin-top: 20px;
    display: flex;
    align-items: center;
    
    
  }
  
  .header-area .logo img {
    width: 150px; /* Scale logo size */
    padding-left: 50px;
  }


/* Menu trigger styles */
.header-area .main-nav .menu-trigger {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    z-index: 99;
  }
  
  .header-area .main-nav .menu-trigger span,
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    background-color: #7453fc;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    transition: all 0.4s;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    content: "";
  }
  
  .header-area .main-nav .menu-trigger span {
    top: 13px;
  }
  
  .header-area .main-nav .menu-trigger span:before {
    top: -10px;
  }
  
  .header-area .main-nav .menu-trigger span:after {
    top: 10px;
  }
  
  .header-area .main-nav .menu-trigger.active span {
    background-color: transparent;
  }
  
  .header-area .main-nav .menu-trigger.active span:before {
    transform: translateY(10px) translateX(8px) rotate(50deg);
  }
  
  .header-area .main-nav .menu-trigger.active span:after {
    transform: translateY(-10px) translateX(8px) rotate(-50deg);
  }
/* Navigation Menu */
.header-area .main-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px; 
    
}

.header-area .main-nav .nav {
    display: flex;
    gap: 20px; 
    align-items: center;
    justify-content: center;
    padding-top: 7px;
}

.header-area .main-nav .nav.open {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;; /* Adjust based on header height */
    text-align: center;
    background-color: #fff;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 130px;
    
  }
  

.header-area .main-nav .nav li {
    list-style: none;
}

.header-area .main-nav .nav li a {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    color: #2a2a2a;
    padding: 8px 15px;
    border-radius: 18px;
    text-decoration: none;
    
}

 
.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover,
.background-header .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover {
  padding-left: 25px !important;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #fff;
  background-color: #2667ff;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fff;
  opacity: 1;
}
/* Dropdown submenu styles */
.header-area .main-nav .nav li.has-sub {
    position: relative;
    padding-right: 15px;
}

.header-area .main-nav .nav li .sub-menu {
    display: none; 
    position: absolute;
    top: 124%;
    left: -150%;
    width: 400px;
    background-color: #fff; /* Background color of the dropdown */
    padding-left: 20px ; /* Padding around dropdown items */
    padding-right: 20px;
    min-width: 600px; /* Minimum width of the dropdown */
    z-index: 999; /* Ensure dropdown appears above other content */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for dropdown */
    column-count: 3; /* Arrange items in 2 columns */
    column-gap: 10px; /* Gap between columns */
    border-radius: 5px;
    text-align: left;
    border: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding-top: 7px;
}

/* Show submenu on hover */
.header-area .main-nav .nav li:hover .sub-menu {
    display: block;
    
}

/* Submenu styling */
.header-area .main-nav .nav li .sub-menu li a {
    display: block;
    padding: 10px 0;
    color: #333;
    text-decoration: none;
    background-color: white;
    font-size: 14px;
    text-align: center;
}

/* Hover effects for submenu items */
.header-area .main-nav .nav li .sub-menu li a:hover {
    background-color: black;
    color: #fefefe;
    text-transform: capitalize;
}

.header-area .main-nav .nav li.has-sub.open .sub-menu {
    display: block; /* Show submenu when the parent li has the 'open' class */
}

.rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .header-area {
      height: 90px;
      padding: 0 0px;
      
      box-shadow: 0 8px 15px -5px #7453fc;
      overflow: visible; /* Ensure the content doesn't get clipped */
      z-index: 1000; /* Bring header above other content */
  }

  .header-area .main-nav .logo-container {
      height: 100px;
      margin-top: 20px;
      align-items: center;
  }

  .header-area .logo img {
      width: 150px;
      padding-left: 50px;
  }

  .header-area .main-nav .nav.open {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;; /* Adjust based on header height */
    text-align: left;
    background-color: #fff;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 130px;
    
  }

  .header-area .main-nav .menu-trigger {
      display: block; /* Show menu trigger on mobile */
      z-index: 1001; /* Ensure it's above other content */
  }
  
  .header-area .main-nav .nav {
      display: none;
      flex-direction: column; /* Stack the elements vertically */
      align-items: stretch; /* Ensure items take up full width */
      overflow: visible;
      position: relative; /* Make sure it's positioned relative to the parent */
      z-index: 999; /* Ensure it's behind the header */
  }

  .header-area .main-nav .nav li:hover .sub-menu {
    display: none;
}

  /* Make sure the submenu is visible without hiding anything else */
  .header-area .main-nav .nav li .sub-menu {
      position: absolute; /* Use relative so it doesn’t go outside parent */
      
      left: 0;
      min-width: 100%; /* Full width for mobile */
      width: 100%;
      padding-left: 10px;
      padding-right: 18px;
      column-count: 2; /* Display items in 2 columns */
      border-radius: 0; /* Remove border-radius */
      box-shadow: none; /* Remove shadow for simplicity */
      z-index: 998; /* Keep below the header and nav */
  }

  .header-area .main-nav .nav li .sub-menu li a {
      font-size: 10px;
      text-align: center;
      padding: 10px 0;
  }

  /* Ensure the content section isn’t affected */
  .home-section, .about-section, .other-content {
      z-index: 0; /* Lower than the nav */
      position: relative; /* Allow them to stay in normal document flow */
      margin-top: 100px; /* Add some spacing from the header */
  }
}

  

/* ---------------------------------------------
Banner
--------------------------------------------- */
.banner-area {
  position: relative;
  background: #000;
  color: #fff;
  text-align: center;
  padding: 100px 0;
}

.banner-area h1 {
  font-size: 48px;
  font-weight: 700;
}

.banner-area p {
  font-size: 18px;
  line-height: 28px;
}

.main-banner {
    background-color: #fff;
    padding-top: 160px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-height: fit-content;
    margin: 0;
}
  
.header-text {
    margin-bottom: 0 !important; /* Force margin removal */
}
  
.main-banner h6 {
    font-size: min(5vw, 100px);
    margin-bottom: 1%;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    background-clip: text;
    background-image: radial-gradient(circle,  #333333, #4d4d4d, #666666, #808080, #999999, #b3b3b3);;
    color:#000;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: var(--stroke-width);
}
 
.main-banner h1 {
    background-clip: text;
    background-image: linear-gradient(to right, #000000, #1a1a1a, #333333, #4d4d4d, #666666);;
    color: #00011912;
    font-size: min(2vw, 100px);
    letter-spacing: 0px; 
    margin-top: 1%;
    margin-bottom: auto;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: var(--stroke-width);
}
  
.main-banner h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 66px;
    text-transform: uppercase;
}
  
.main-banner p {
    margin-top: 20px;
}
  
.main-banner .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: start;
}
  
.main-banner .border-button {
    margin-right: 15px;
}
  
.main-banner .owl-banner {
    margin-top: -40px;
}
  
.main-banner .owl-nav {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    bottom: -76px;
    text-align: center;
}
  
.main-banner .owl-nav .owl-prev {
    margin-right: 7.5px;
}
  
.main-banner .owl-nav .owl-next {
    margin-left: 7.5px;
}
  
.main-banner .owl-nav .owl-prev span,
.main-banner .owl-nav .owl-next span {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 24px;
    display: inline-block;
    color: #7453fc;
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    transition: all .3s;
}
  
.main-banner .owl-nav .owl-prev span:hover,
.main-banner .owl-nav .owl-next span:hover {
    opacity: 1;
}
  
.centered-hr {
    width: 50%; /* The width of the hr will be 50% of the container */
    max-width: 100%; /* Ensures it doesn't exceed the container width */
    margin: 0 auto; /* Center align */
    border: none; /* Removes the default border */
    height: 3px; /* Height of the hr */
    background-color: #2667ff; /* Color of the hr */
}
  
.video-container {
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    margin-top: 20px; 
}

.video-container iframe, .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
   
}
  /* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .main-banner {
        padding-top: 80px;
        
    }

    .main-banner h6 {
        padding-top: 100px;
        font-size: min(5vw, 100px);
        
    }

    .main-banner h1 {
        font-size: min(2vw, 100px)
    }

    .main-banner h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .main-banner .buttons {
        flex-direction: column;
        align-items: center;
    }

    .main-banner .border-button {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .video-container {
        
            width: 100%;
            padding-bottom: 56.25%; /* 16:9 aspect ratio */
            position: relative;
            margin-top: 20px; 
    
        
      }

}

@media (max-width: 480px) {
    .main-banner {
        padding-top: 40px;
    }

    .main-banner h6 {
        padding-top: 100px;
        font-size: min(5vw, 100px);
        
    }

    .main-banner h1 {
        font-size: min(2vw, 100px)
    }

    .main-banner h2 {
        font-size: 25px;
        line-height: 35px;
    }

    .main-banner .buttons {
        flex-direction: column;
        align-items: center;
    }

    .main-banner .border-button {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .video-container {
        
            width: 100%;
            padding-bottom: 56.25%; /* 16:9 aspect ratio */
            position: relative;
            margin-top: 20px; 
    
        
      }
}

  @media (max-width: 768px) {
    .centered-hr {
      width: 70%; /* Increase the width for smaller screens */
      height: 1px; /* Reduce height for smaller screens */
    }
    
  }
  
  @media (max-width: 480px) {
    .centered-hr {
      width: 80%; /* Further increase width for very small screens */
      height: 1px; /* Keep the height manageable */
    }
    
  }
  [data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
    transition-duration: 0.5s; /* Adjust this value */
  }
  
  /* 
---------------------------------------------
workprocess
--------------------------------------------- 
*/

  .item-details-page {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 0px 0px 70px 0px;
    position: relative;
  }

  .item-details-pagecontact {
    background-image: url(http://localhost:3000/static/media/dark-bg.610bdcdb7ffb44776eb2.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 0px 0px 0px 0px;
    position: relative;
}
  
  .item-details-page:after {
    background-image: url(../images/category-collection-dec.png);
    width: 300px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 30px;
    content: '';
    z-index: 1;
  }
  
  .item-details-page .left-image {
    margin-right: 30px;
  }
  
  .item-details-page h2 {
    /* font-size: 20px; */
    /* margin-bottom: 25px; */
    /* margin-top: -100px; */
  }
  
  .item-details-page span.author {
    display: flex;
    margin-bottom: 30px;
  }
  
  .item-details-page span.author img {
    margin-right: 15px;
  }
  
  .item-details-page span.author h6 {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }
  
  .item-details-page span.author a {
    color: #7453fc;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .item-details-page p {
    margin-bottom: 30px;
  }
  
  .item-details-page span {
    color: #fff;
    display: inline-block;
    font-size: 15px;
  }
  
  .item-details-page span strong {
    font-size: 20px;
    color: #7453fc;
  }
  
  .item-details-page p {
    position: relative;
    z-index: 2;
  }
  
  .item-details-page span.bid,
  .item-details-page span.owner,
  .item-details-page span.ends  {
    line-height: 35px;
    position: relative;
    z-index: 2;
  }
  
  .item-details-page span.bid em,
  .item-details-page span.ends em,
  .item-details-page span.owner em {
    font-style: normal;
    color: #afafaf;
  }
  
  .item-details-page form {
    margin-top: 40px;
  }
  
  
  .item-details-page form label {
    color: #afafaf;
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
  }
  
  .item-details-page form input {
    width: 100px;
    height: 46px;
    border: 1px solid #7453fc;
    outline: none;
    border-radius: 23px;
    background-color: transparent;
    color: #fff;
    text-align: center;
    margin-right: 15px;
  }
  
  .item-details-page form input::placeholder {
    color: #fff;
  }
  
  .item-details-page button {
    font-size: 14px;
    color: #fff;
    background-color: #7453fc;
    border: 1px solid #7453fc;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 25px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    transition: all .3s;
    position: relative;
    overflow: hidden;
  }
  
  .item-details-page button:after {
    width: 50%;
    height: 2px;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  
  .item-details-page button:hover {
    background-color: #fff;
    color: #fff;
    border: 1px solid #fff;
  }

  .create-nft p {
    margin-right: 30px;
  }
  
  .current-bid {
    margin-top: 120px;
  }
  
  .current-bid select {
    float: right;
    width: 150px;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .current-bid .mini-heading h4 {
    background-color: #7453fc;
    display: inline-block;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 22px;
  }
  
  .current-bid .item {
    position: relative;
    z-index: 2;
    margin-top: 30px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    background-color: #282b2f;
    border: 1px solid #404245;
  }
  
  .current-bid .item .left-img {
    flex-basis: 80%;
    display: inline-flex;
  }
  
  .current-bid .item .right-content {
    padding: 30px;
    width: 100%;
  }
  
  .current-bid .item .right-content h4 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  
  .current-bid .item .right-content a {
    font-size: 15px;
    color: #7453fc;
    font-weight: 700;
  }
  
  .current-bid .item .right-content .line-dec {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 25px 0px;
  }
  
  .current-bid .item .right-content h6 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  .current-bid .item .right-content h6 em {
    font-size: 18px;
    color: #7453fc;
    font-style: normal;
    font-weight: 700;
  }
  
  .current-bid .item .right-content span.date {
    font-size: 15px;
    color: #7453fc;
  }

  .animate{
    /* color: #2e78fd;  */
    font-size: 45px;
    background: linear-gradient(to left,#000000 35%, rgb(255, 0, 0));
    background-size: 200% auto;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradient 3s linear infinite;
  }
  
  @keyframes gradient{
    0%{
      background-position: 0% 75%;
    }
    50%{
      background-position: 100% 30%;
    }
    100%{
      background-position: 0% 70%;
    }
  }
  /* Responsive Styles */
@media (max-width: 1200px) {
  .item-details-page .left-image {
    margin-right: 20px;
  }

  .item-details-page h2 {
    font-size: 22px;
  }

  .current-bid .item {
    flex-direction: column;
  }
  .create-nft p {
    margin-right: 0px;
  }
}

@media (max-width: 992px) {
  .item-details-page:after {
    right: 10px;
  }

  .current-bid select {
    width: 100%;
    margin-top: 10px;
  }

  .item-details-page form input {
    width: 80px;
  }

  .current-bid .mini-heading h4 {
    font-size: 18px;
  }
  .create-nft p {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .item-details-page .left-image {
    margin-right: 0;
  }

  .item-details-page h2 {
    font-size: 20px;
  }

  .item-details-page span.author {
    flex-direction: column;
    align-items: center;
  }

  .item-details-page form input {
    width: 70px;
    height: 40px;
  }

  .current-bid .item {
    padding: 20px;
  }

  .current-bid .mini-heading h4 {
    font-size: 16px;
  }
  .create-nft p {
    margin-right: 0px;
  }
}

@media (max-width: 576px) {
  .item-details-page {
    padding: 0px 0px 50px 0px;
  }

  .current-bid .item {
    flex-direction: column;
    padding: 15px;
  }

  .item-details-page span.author {
    text-align: center;
  }

  .current-bid .mini-heading h4 {
    font-size: 14px;
    padding: 8px 16px;
  }
  .create-nft p {
    margin-right: 0px;
  }
}

@media (max-width: 400px) {
  .item-details-page h2 {
    font-size: 18px;
  }

  .item-details-page form input {
    width: 60px;
  }

  .current-bid .mini-heading h4 {
    font-size: 12px;
    padding: 6px 12px;
  }

  .current-bid .item {
    padding: 10px;
  }
}
  /* 
---------------------------------------------
Author Page Style
--------------------------------------------- 
*/

.author-page {
  background-image: url(../images/dark-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* padding: 120px 0px 120px 0px; */
  position: relative;
}

.author-page .author {
  display: flex;
}

.author-page .author h4 {
  margin-top: 60px;
  font-size: 20px;
  margin-left: 30px;
}

.author-page .author h4 a {
  font-size: 15px;
  color: #7453fc;
}

.author-page .right-info {
  background-color: #282b2f;
  border: 1px solid #404245;
  padding: 20px 30px;
  border-radius: 20px;
  margin-left: 100px;
}

.author-page .right-info .info-item {
  margin-bottom: 20px;
}

.author-page .right-info i {
  color: #7453fc;
  font-size: 22px;
}

.author-page .right-info h6 {
  font-size: 20px;
  color: #7453fc;
  margin-top: 4px;
}

.author-page .right-info h6 em {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

.author-page .right-info h5 {
  font-size: 20px;
  color: #afafaf;
  margin-top: 10px;
}

.author-page .right-info .main-button a {
  width: 100%;
  text-align: center;
}

.author-page .section-heading {
  margin-top: 80px;
  text-align: left;
}

.author-page .section-heading .line-dec {
  /* margin-left: 0; */
}

.author-page .item span.author {
  display: inline-flex;
  width: 100%;
}

.author-page .item span.author img {
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.author-page .item img {
  margin-top: -32px;
  position: relative;
  z-index: 1;
}

.author-page .item h4 {
  font-size: 20px;
  margin-top: 30px;
}

.author-page .item .line-dec {
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,0.2);
  margin: 30px 0px;
}

.author-page .item {
  background-color: #282b2f;
  border: 1px solid #404245;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 52px;
  height: 425px;
}

.author-page .item span {
  color: #fff;
  display: inline-block;
  font-size: 15px;
}

.author-page .item span strong {
  font-size: 20px;
}

.author-page .item span.category {
  text-align: right;
}

.author-page .item .main-button {
  margin-top: 40px;
  /* margin-bottom: -60px; */
  text-align: center;
}

/* Responsive Design for Tablet Screens */
@media screen and (max-width: 1024px) {
.author-page .author {
    flex-direction: column; /* Change flex direction for smaller screens */
    align-items: center; /* Center the content */
}

.author-page .right-info {
    margin-left: 0; /* Remove the left margin on smaller screens */
    margin-top: 20px; /* Add some spacing above the right info section */
    padding: 20px; /* Adjust padding */
}

.author-page .author h4 {
    margin-left: 0; /* Remove left margin for heading */
    text-align: center; /* Center-align the text */
    font-size: 18px; /* Adjust font size */
}

.author-page .right-info h6 {
    font-size: 18px; /* Adjust the font size for heading */
}

.author-page .item {
  background-color: #282b2f;
  border: 1px solid #404245;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 52px;
  height: 425px;
}

.author-page .item img {
  margin-top: -32px;
  position: relative;
  border-radius: 20px;
  height: 300px;
  width: min(300vw, 300px);
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
}

/* Further responsiveness for mobile phones */
@media screen and (max-width: 768px) {
.author-page .author {
  flex-direction: column; /* Change flex direction for smaller screens */
  align-items: center; /* Center the content */
}

.author-page .right-info {
  margin-left: 0; /* Remove the left margin on smaller screens */
  margin-top: 20px; /* Add some spacing above the right info section */
  padding: 20px; /* Adjust padding */
}

.author-page .author h4 {
  margin-left: 0; /* Remove left margin for heading */
  text-align: center; /* Center-align the text */
  font-size: 18px; /* Adjust font size */
}

.author-page .right-info h6 {
  font-size: 18px; /* Adjust the font size for heading */
}

.author-page .item {
  height: auto; /* Remove fixed height for responsiveness */
  margin-bottom: 40px; /* Adjust margin */
  margin-left: 2%;
  margin-right: 2%;
}

.author-page .item img {
  margin-top: -32px;
  position: relative;
  border-radius: 20px;
  height: 300px;
  width: min(300vw, 300px);
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
} 
  
@media screen and (max-width: 1500px) {
  .author-page .author {
    flex-direction: column; /* Change flex direction for smaller screens */
    align-items: center; /* Center the content */
  }
  
  .author-page .right-info {
    margin-left: 0; /* Remove the left margin on smaller screens */
    margin-top: 20px; /* Add some spacing above the right info section */
    padding: 20px; /* Adjust padding */
  }
  
  .author-page .author h4 {
    margin-left: 0; /* Remove left margin for heading */
    text-align: center; /* Center-align the text */
    font-size: 18px; /* Adjust font size */
  }
  
  .author-page .right-info h6 {
    font-size: 18px; /* Adjust the font size for heading */
  }
  
  .author-page .item {
    height: auto; /* Remove fixed height for responsiveness */
    margin-bottom: 40px; /* Adjust margin */
    margin-left: 2%;
    margin-right: 2%;
  }
  
  .author-page .item img {
    margin-top: -32px;
    position: relative;
    border-radius: 20px;
    height: 300px;
    width: min(300vw, 300px);
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  } 
  /* author page genetrative ai */
  .author-page1 {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* padding: 120px 0px 120px 0px; */
    position: relative;
    display: flex;
  }

  .author-page1 {
    display: flex;
    justify-content: space-between; /* Ensures equal spacing between items */
    align-items: stretch; /* Ensures all items stretch to equal height */
    flex-wrap: wrap; /* Items wrap to the next line when needed */
    gap: 20px; /* Adds some gap between the boxes */
  }
  
  /* Flexbox styling for individual item containers */
  .author-page1 .item {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 30px;
    border-radius: 20px;
    flex-grow: 1; /* Ensures items grow to fill available space */
    display: flex;
    flex-direction: column; /* Items inside stack vertically */
    justify-content: space-between; /* Ensures content distributes evenly */
    height: auto; /* Height adapts based on content */
    margin-bottom: 52px; /* Maintain spacing at the bottom */
  }
  
  .author-page1 .author {
    display: flex;
  }
  
  .author-page1 .author h4 {
    margin-top: 60px;
    font-size: 20px;
    margin-left: 30px;
  }
  
  .author-page1 .author h4 a {
    font-size: 15px;
    color: #7453fc;
  }
  
  .author-page1 .right-info {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 20px 30px;
    border-radius: 20px;
    margin-left: 100px;
  }
  
  .author-page1 .right-info .info-item {
    margin-bottom: 20px;
  }
  
  .author-page1 .right-info i {
    color: #7453fc;
    font-size: 22px;
  }
  
  .author-page1 .right-info h6 {
    font-size: 20px;
    color: #7453fc;
    margin-top: 4px;
  }
  
  .author-page1 .right-info h6 em {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
  }
  
  .author-page1 .right-info h5 {
    font-size: 20px;
    color: #afafaf;
    margin-top: 10px;
  }
  
  .author-page1 .right-info .main-button a {
    width: 100%;
    text-align: center;
  }
  
  .author-page1 .section-heading {
    margin-top: 80px;
    text-align: left;
  }
  
  .author-page1 .section-heading .line-dec {
    /* margin-left: 0; */
  }
  
  .author-page1 .item span.author {
    display: inline-flex;
    width: 100%;
  }
  
  .author-page1 .item span.author img {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    height:200px;
  }
  
  .author-page1 .item img {
    margin-top: -32px;
    position: relative;
    z-index: 1;
    height:221px;
  }
  
  .author-page1 .item h4 {
    font-size: 17px;
    margin-top: 30px;
  }
  
  .author-page1 .item .line-dec {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
    margin: 30px 0px;
  }
  
  .author-page1 .item {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 52px;
    height: auto;
   
  }

  
  
  .author-page1 .item span {
    color: #fff;
    display: inline-block;
    font-size: 15px;
  }
  
  .author-page1 .item span strong {
    font-size: 20px;
  }
  
  .author-page1 .item span.category {
    text-align: right;
  }
  
  .author-page1 .item .main-button {
    margin-top: 20px;
    /* margin-bottom: -60px; */
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
  
    .author-page1 .item {
     
      height: auto;
      margin-left: 2%;
      margin-right: 2%;
    }
    /* CSS styles specific to mobile devices */
  }
  
  
  /* AR/VR */
  .author-page2 {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* padding: 120px 0px 120px 0px; */
    position: relative;
  }
  
  .author-page2 .author {
    display: flex;
  }
  
  .author-page2 .author h4 {
    margin-top: 60px;
    font-size: 20px;
    margin-left: 30px;
  }
  
  .author-page2 .author h4 a {
    font-size: 15px;
    color: #7453fc;
  }
  
  .author-page2 .right-info {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 20px 30px;
    border-radius: 20px;
    margin-left: 100px;
  }
  
  .author-page2 .right-info .info-item {
    margin-bottom: 20px;
  }
  
  .author-page2 .right-info i {
    color: #7453fc;
    font-size: 22px;
  }
  
  .author-page2 .right-info h6 {
    font-size: 20px;
    color: #7453fc;
    margin-top: 4px;
  }
  
  .author-page2 .right-info h6 em {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
  }
  
  .author-page2 .right-info h5 {
    font-size: 20px;
    color: #afafaf;
    margin-top: 10px;
  }
  
  .author-page2 .right-info .main-button a {
    width: 100%;
    text-align: center;
  }
  
  .author-page2 .section-heading {
    margin-top: 80px;
    text-align: left;
  }
  
  .author-page2 .section-heading .line-dec {
    /* margin-left: 0; */
  }
  
  .author-page2 .item span.author {
    display: inline-flex;
    width: 100%;
  }
  
  .author-page2 .item span.author img {
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .author-page2 .item img {
    margin-top: -32px;
    position: relative;
    z-index: 1;
    height:200px ;
  }
  
  .author-page2 .item h4 {
    font-size: 20px;
    margin-top: 30px;
  }
  
  .author-page2 .item .line-dec {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
    margin: 30px 0px;
  }
  
  .author-page2 .item {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 52px;
    height: 500px;
  }
  
  .author-page2 .item span {
    color: #fff;
    display: inline-block;
    font-size: 15px;
  }
  
  .author-page2 .item span strong {
    font-size: 20px;
  }
  
  .author-page2 .item span.category {
    text-align: right;
  }
  
  .author-page2 .item .main-button {
    margin-top: 20px;
    /* margin-bottom: -60px; */
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
  
    .author-page2 .item {
     
      height: auto;
    }
    /* CSS styles specific to mobile devices */
  }
  
  

/* General mobile styles */

  @media (max-width: 768px) {
    .top-seller .item {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .main-banner .owl-banner {
      margin-top: 80px;
    }
    .categories .item {
      margin-bottom: 35px;
    }
    .create-nft .section-heading,
    .currently-market .section-heading {
      text-align: center;
    }
    .currently-market-item {
      width: 100%;
    }
    .create-nft .section-heading .line-dec,
    .currently-market .section-heading .line-dec {
      margin: 0 auto;
    }
    .create-nft .main-button,
    .currently-market .filters {
      text-align: center;
    }
    .currently-market .filters {
      margin-bottom: 30px;
      margin-top: -30px;
    }
    .create-nft p {
      margin-right: 0px;
    }
    .create-nft .first-item:after,
    .create-nft .second-item:after,
    .create-nft .first-item .number,
    .create-nft .second-item .number {
      display: none;
    }
    .create-nft .item {
      text-align: center;
      margin-top: 45px;
    }
    .discover-items #search-form {
      margin-bottom: 40px;
      margin-top: -30px;
    }
    .discover-items #search-form fieldset {
      margin-bottom: 30px;
    }
    .top-seller .item h6 {
      font-size: 16px;
    }
    .item-details-page .left-image {
      margin-right: 0px;
      margin-bottom: 30px;
    }
    .current-bid select {
      float: none;
    }
    .author-page .author {
      justify-content: center;
    }
    .author-page .right-info {
      margin-left: 0px;
      margin-top: 30px;
    }
  }

  @media (max-width: 480px) {
    .top-seller .item {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .main-banner .owl-banner {
      margin-top: 80px;
    }
    .categories .item {
      margin-bottom: 35px;
    }
    .create-nft .section-heading,
    .currently-market .section-heading {
      text-align: center;
    }
    .currently-market-item {
      width: 100%;
    }
    .create-nft .section-heading .line-dec,
    .currently-market .section-heading .line-dec {
      margin: 0 auto;
    }
    .create-nft .main-button,
    .currently-market .filters {
      text-align: center;
    }
    .currently-market .filters {
      margin-bottom: 30px;
      margin-top: -30px;
    }
    .create-nft p {
      margin-right: 0px;
    }
    .create-nft .first-item:after,
    .create-nft .second-item:after,
    .create-nft .first-item .number,
    .create-nft .second-item .number {
      display: none;
    }
    .create-nft .item {
      text-align: center;
      margin-top: 45px;
    }
    .discover-items #search-form {
      margin-bottom: 40px;
      margin-top: -30px;
    }
    .discover-items #search-form fieldset {
      margin-bottom: 30px;
    }
    .top-seller .item h6 {
      font-size: 16px;
    }
    .item-details-page .left-image {
      margin-right: 0px;
      margin-bottom: 30px;
      overflow: hidden;
    }
    .current-bid select {
      float: none;
    }
    .author-page .author {
      justify-content: center;
    }
    .author-page .right-info {
      margin-left: 0px;
      margin-top: 30px;
    }
  }
  

  .categories-collections {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 70px 0px 70px 0px;
    position: relative;
  }
  
  .categories-collections:after {
    background-image: url(../images/category-collection-dec.png);
    width: 300px;
    height: 282px;
    position: absolute;
    bottom: 0;
    right: 30px;
    content: '';
  }
  
  .categories .item {
    background-color: #282b2f;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #404245;
    position: relative;
    height: 230px;
  
  }
  
  .categories .item .icon {
    width: 62px;
    height: 62px;
    display: inline-block;
    text-align: center;
    line-height: 62px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .categories .item .icon img {
    max-width: 31px;
  }
  
  .categories .item h4 {
    margin-top: 15px;
    font-size: 20px;
  }
  
  .categories .item .icon-button a {
    display: inline-block;
    width: 46px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #7453fc;
    border-radius: 50%;
    font-size: 18px;
    position: absolute;
    left: 50%;
    bottom: -23px;
    transform: translateX(-23px);
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
  }
  
  .categories .item .icon-button a:hover {
    background-color: #7453fc;
    color: #fff;
  }
  
  .categories .item:hover .icon-button a {
    visibility: visible;
    opacity: 1;
  }
  
  .collections {
    margin-top: 100px;
  }
  
  .collections .item img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  .collections .item .down-content {
    background-color: #282b2f;
    border: 1px solid #404245;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 30px;
  }
  
  .collections .item .down-content h4 {
    font-size: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .collections .item {
    padding-bottom: 60px;
  }
  
  .collections .item span {
    color: #fff;
    display: inline-block;
    width: 48%;
    font-size: 15px;
  }
  
  .collections .item span strong {
    font-size: 20px;
  }
  
  .collections .item span.category {
    text-align: right;
  }
  
  .collections .item .main-button a {
    width: 100%;
    text-align: center;
  }
  
  .collections .item .main-button {
    margin-top: 20px;
    margin-bottom: -60px;
  }
  
  .collections .item .main-button a:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #7453fc;
  }
  
  .collections .owl-nav {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50px);
    text-align: center;
  }
  
  .collections .owl-nav .owl-prev {
    position: absolute;
    left: -23px;
  }
  
  .collections .owl-nav .owl-next {
    position: absolute;
    right: -23px;
  }
  
  .collections .owl-nav .owl-prev span,
  .collections .owl-nav .owl-next span  {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 24px;
    display: inline-block;
    color: #7453fc;
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    transition: all .3s;
  }
  
  .collections .owl-nav .owl-prev span:hover,
  .collections .owl-nav .owl-next span:hover {
    opacity: 1;
  }
  
  
  /* 
  ---------------------------------------------
  Create NFT Style
  --------------------------------------------- 
  */
  
  .create-nft {
    background-image: url(../images/main-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 65px 0px;
    position: relative;
  }
  
  .create-nft .section-heading .line-dec {
    margin-left: 0%;
    background-color: #fff;
  }
  
  .create-nft .section-heading {
    text-align: left;
    margin-bottom: 50px;
  }
  
  .create-nft .main-button {
    text-align: right;
  }
  
  .create-nft .item {
    margin-top: 30px;
    position: relative;
  }
  
  .create-nft .first-item .number {
    position: absolute;
    right: 0;
    top: -20px;
  }
  
  .create-nft .first-item:after {
    position: absolute;
    width: 1px;
    height: 95%;
    background-color: rgba(255, 255, 255, 0.2);
    content: '';
    right: 5px;
    top: 5%;
  }
  
  .create-nft .second-item .number {
    position: absolute;
    right: 0;
    top: -20px;
  }
  
  .create-nft .second-item:after {
    position: absolute;
    width: 1px;
    height: 95%;
    background-color: rgba(255, 255, 255, 0.2);
    content: '';
    right: 5px;
    top: 5%;
  }
  
  .item .icon {
    width: 92px;
    height: 92px;
    display: inline-block;
    text-align: center;
    line-height: 92px;
    background-color: #fff;
    border-radius: 50%;
  }
  .item .icon3 {
    width: 92px;
    height: 92px;
    display: inline-block;
    text-align: center;
    line-height: 92px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .create-nft .item .icon img {
    max-width: 90px;
  }
  
  .create-nft .item .icon3 img {
    max-width: 80px;
  }
  
  .create-nft h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  c
  
  .create-nft .item a {
    color: #3CF;
  }
  
  
  
  /* 
  ---------------------------------------------
  Currently Market Style
  --------------------------------------------- 
  */
  
  .currently-market {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 120px 0px;
    position: relative;
  }
  
  .currently-market-item {
    width: 45%;
    margin: 0 auto;
  }
  
  .currently-market .section-heading {
    text-align: left;
  }
  
  .currently-market .section-heading .line-dec {
    margin-left: 0;
  }
  
  .currently-market .filters {
    text-align: right;
  }
  
  .currently-market .filters ul li {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: all .3s;
  }
  
  .currently-market .filters ul li:first-child {
    margin-left: 0px;
  }
  
  
  .currently-market .filters ul li.active,
  .currently-market .filters ul li:hover {
    background-color: #7453fc;
  }
  
  .currently-market .item {
    background-color: #282b2f;
    border: 1px solid #404245;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    margin-bottom: 30px;
  }
  
  .currently-market .item .right-content {
    margin-left: 30px;
    width: 100%;
  }
  
  .currently-market .item .right-content h4 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  
  .currently-market .item .right-content span.author {
    display: flex;
    margin-bottom: 25px;
  }
  
  .currently-market .item .right-content span.author img {
    margin-right: 15px;
  }
  
  .currently-market .item .right-content span.author h6 {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }
  
  .currently-market .item .right-content span.author a {
    color: #7453fc;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .currently-market .item span {
    color: #fff;
    display: inline-block;
    font-size: 15px;
  }
  
  .currently-market .item .line-dec {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 25px;
    margin-top: 30px;
  }
  
  .currently-market .item span strong {
    font-size: 20px;
  }
  
  .currently-market .item span.bid {
    line-height: 35px;
  }
  
  .currently-market .item span.bid em,
  .currently-market .item span.ends em {
    font-style: normal;
  }
  
  .currently-market .item span.ends {
    text-align: right;
    float: right;
    line-height: 35px;
  }
  
  .currently-market .item .text-button {
    margin-top: 40px;
  }
  
  .currently-market .item .text-button a {
    font-size: 15px;
    font-weight: 700;
    color: #7453fc;
    border-bottom: 1px solid #7453fc;
  }
  
  .main-button1 a {
    font-size: 14px;
    color: #fff;
    background-color: #7453fc;
    border: 1px solid #7453fc;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 25px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    transition: all .3s;
    position: relative;
    overflow: hidden;
  }
  
  .main-button1 a:after {
    width: 78%;
    height: 2px;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  
  .main-button1 a:hover {
    background-color: #fff;
    color: #7453fc;
    border: 1px solid #fff;
  }
  
  .container1 {
      width: 1000px;
      display: flex;
      flex-wrap: wrap;
      /* justify-content: space-around; */
  }
  .container1 .btn {
      position: relative;
      top: 0;
      left: 0;
      width: 250px;
      height: 50px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .container1 .btn a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(88 156 255 / 22%);
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 30px;
      padding: 10px;
      letter-spacing: 1px;
      text-decoration: none;
      overflow: hidden;
      color: #fff;
      font-weight: 400px;
      z-index: 1;
      transition: 0.5s;
      backdrop-filter: blur(15px);
  }
  .container1 .btn:hover a {
      letter-spacing: 3px;
  }
  .container1 .btn a::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
      transform: skewX(45deg) translate(0);
      transition: 0.5s;
      filter: blur(0px);
  }
  .container1 .btn:hover a::before {
      transform: skewX(45deg) translate(200px);
  }
  .container1 .btn::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translatex(-50%);
      bottom: -5px;
      width: 30px;
      height: 10px;
      background: #f00;
      border-radius: 10px;
      transition: 0.5s;
      transition-delay: 0.5;
  }
  .container1 .btn:hover::before /*lightup button*/ {
      bottom: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
  }
  
  .container1 .btn::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translatex(-50%);
      top: -5px;
      width: 30px;
      height: 10px;
      background: #f00;
      border-radius: 10px;
      transition: 0.5s;
      transition-delay: 0.5;
  }
  .container1 .btn:hover::after /*lightup button*/ {
      top: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
  }
  .container1 .btn:nth-child(1)::before, /*chnage 1*/
  .container1 .btn:nth-child(1)::after {
      background: #ff1f71;
      box-shadow: 0 0 5px #ff1f71, 0 0 15px #ff1f71, 0 0 30px #ff1f71,
          0 0 60px #ff1f71;
  }
  .container1 .btn:nth-child(2)::before, /* 2*/
  .container1 .btn:nth-child(2)::after {
      background: #2db2ff;
      box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
          0 0 60px #2db2ff;
  }
  .container1 .btn:nth-child(3)::before, /* 3*/
  .container1 .btn:nth-child(3)::after {
      background: #1eff45;
      box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
          0 0 60px #1eff45;
  }
  
  .container1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;  Center align buttons horizontally */
  }
  
  .container1 .btn {
    width: 45%; /* Adjust the width as needed */
    max-width: 250px; /* Set a maximum width */
    height: 50px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Additional CSS for smaller screens */
  @media screen and (max-width: 768px) {
    .container1 .btn {
        width: 90%; /* Adjust width for smaller screens */
    }
  }
  
  @use postcss-preset-env {
    stage: 0;
  }
  
  :root {
    --color-background: #000119;
    --stroke-width: calc(1em / 16);
    --font-size: 7vmin;
    --font-weight: 700;
    --letter-spacing: calc(1em / 8);
  }
  

  /* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer {
    background: rgb(125,90,254);
    background: linear-gradient(132deg, rgba(125,90,254,1) 25%, rgba(160,84,244,1) 100%);
    /* text-align: center; */
    padding: 0px 0px;
  }
  
  footer p {
    color: #fff;
    font-size: 15px;
  }
  
  footer p a {
    color: #fff;
    font-weight: 500;
    transition: all .3s;
  }
  
  footer p a:hover {
    color: #fff;
    opacity: 0.75;
  }
  .footer-icons a{
    padding: 5px;
    color: white;
  }
  
  .footer-enquiry a{
    color: white;
    font-size: 25px;
  }
  .social-icons {
    display: flex;
    gap: 10px; /* Adjust spacing between icons as needed */
  }
  .responsive-iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 66.67%; /* 3:2 Aspect Ratio (100 / (width / height) * 100) */
    height: 0;
    overflow: hidden;
}

/* Responsive iframe */
.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 10px; /* Keep border radius */
}
  

.normal-space {
    padding: 120px 0px 70px 0px !important;
  }
  
  .page-heading {
    background-image: url(../images/heading-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* padding-top: 250px; */
    text-align: center;
    background-color: #2a2a2a;
  }
  
  .page-heading h6 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  .page-heading h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 66px;
    text-transform: uppercase;
  }
  
  .page-heading span {
    color: #8a75da;
    font-size: 15px;
  }
  
  .page-heading span a {
    color: #fff;
  }
  
  .page-heading .buttons {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
  
  .page-heading .buttons .main-button {
    margin-right: 15px;
  }
  
  .page-heading .buttons .border-button {
    margin-left: 15px;
  }
  
  
  
  /* 
  ---------------------------------------------
  Explore Style
  --------------------------------------------- 
  */
  
  .featured-explore {
    margin-top: 60px;
    margin-bottom: -300px;
  }
  
  .featured-explore .owl-features {
    min-height: 100%;
  }
  
  .featured-explore .item .thumb {
    position: relative;
    overflow: hidden;
  }
  
  .featured-explore .item .thumb:hover .hover-effect {
    visibility: visible;
    opacity: 1;
    right: 30px;
  }
  
  .featured-explore .item .thumb .hover-effect {
    position: absolute;
    right: -100%;
    bottom: 30px;
    padding: 30px 40px;
    border-radius: 20px;
    border: 1px solid rgba(64, 66, 69, 0.9);
    background-color: rgba(40, 43, 47, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
  }
  
  .featured-explore .item .hover-effect .content h4 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  
  .featured-explore .item .hover-effect .content span.author {
    display: flex;
    margin-bottom: 0px;
  }
  
  .featured-explore .item .hover-effect .content span.author img {
    margin-right: 15px;
  }
  
  .featured-explore .item .hover-effect .content span.author h6 {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
  }
  
  .featured-explore .item .hover-effect .content span.author a {
    color: #7453fc;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .featured-explore .owl-nav {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-23px);
    text-align: center;
  }
  
  .featured-explore .owl-nav .owl-prev {
    position: absolute;
    left: 30px;
  }
  
  .featured-explore .owl-nav .owl-next {
    position: absolute;
    right: 30px;
  }
  
  .featured-explore .owl-nav .owl-prev span,
  .featured-explore .owl-nav .owl-next span  {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 22px;
    font-weight: 700;
    display: inline-block;
    color: #7453fc;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.5;
    transition: all .3s;
  }
  
  .featured-explore .owl-nav .owl-prev span:hover,
  .featured-explore .owl-nav .owl-next span:hover {
    opacity: 1;
  }
  
  .discover-items {
    background-image: url(../images/dark-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 400px 0px 120px 0px;
    position: relative;
  }
  
  .discover-items .section-heading {
    text-align: left;
  }
  
  .discover-items .section-heading .line-dec {
    margin-left: 0;
  }
  
  .discover-items #search-form {
    margin-top: 15px;
  }
  
  .discover-items #search-form input,
  .discover-items #search-form select {
    width: 100%;
    height: 46px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 23px;
    background-color: transparent;
    padding: 0px 15px;
    font-size: 14px;
    color: #fff;
  }
  
  .discover-items #search-form select {
    cursor: pointer;
  }
  
  .discover-items #search-form select option {
    background-color: #2a2a2a;
  }
  
  .discover-items #search-form input::placeholder,
  .discover-items #search-form select::placeholder {
    color: #fff;
  }
  
  .discover-items #search-form button {
    font-size: 14px;
    color: #fff;
    background-color: #7453fc;
    border: 1px solid #7453fc;
    height: 46px;
    line-height: 46px;
    text-align: center;
    width: 100%;
    display: inline-block;
    border-radius: 25px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    transition: all .3s;
    position: relative;
    overflow: hidden;
  }
  
  .discover-items #search-form button:after {
    width: 78%;
    height: 2px;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  
  .discover-items #search-form button:hover {
    background-color: #fff;
    color: #7453fc;
    border: 1px solid #fff;
  }
  
  .discover-items .item span.banner {
    font-size: 15px;
    background-color: #7453fc;
    color: #fff;
    font-weight: 500;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
  }
  
  .discover-items .item span.author {
    display: inline-flex;
    width: 100%;
  }
  
  .discover-items .item span.author img {
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .discover-items .item img {
    margin-top: -32px;
    position: relative;
    z-index: 1;
  }
  
  .discover-items .item h4 {
    font-size: 20px;
    margin-top: 30px;
  }
  
  .discover-items .item .line-dec {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
    margin: 30px 0px;
  }
  
  .discover-items .item {
    background-color: #282b2f;
    border: 1px solid #404245;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 52px;
  }
  
  .discover-items .item span {
    color: #fff;
    display: inline-block;
    font-size: 15px;
  }
  
  .discover-items .item span strong {
    font-size: 20px;
  }
  
  .discover-items .item span.category {
    text-align: right;
  }
  
  .discover-items .item .main-button {
    margin-top: 20px;
    margin-bottom: -60px;
    text-align: center;
  }
  
  .top-seller {
    background-image: url(../images/main-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 120px 0px 90px 0px;
    position: relative;
  }
  
  .top-seller .section-heading .line-dec {
    background-color: #fff;
  }
  
  .top-seller .item {
    display: flex;
    margin-bottom: 30px;
  }
  
  .top-seller .item img {
    margin-right: 15px;
  }
  
  .top-seller .item h4 {
    font-size: 20px;
    margin-top: 12px;
    margin-right: 10px;
  }
  
  .top-seller .item h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
  }
  
  .top-seller .item a {
    font-size: 14px;
    cursor: auto;
    color: #fff;
    font-weight: 400;
    margin-top: 5px;
  }
/* 
---------------------------------------------
Create Page Style
--------------------------------------------- 
*/

#contact {
  background-color: #37393c;
  border-radius: 20px;
  padding: 40px 20px; /* Reduce padding */
  margin-bottom: 120px;
  max-width: 600px; /* Set maximum width */
  margin: 0 auto; /* Center the form */
}

#contact input {
  width: 100%;
  height: 40px; /* Reduce height */
  text-align: left;
  padding: 0px 10px; /* Reduce padding */
  background-color: #282b2f;
  border: 1px solid #404245;
  margin-bottom: 20px; /* Reduce margin */
  font-weight: 500;
  color: #afafaf;
}

#contact input::placeholder {
  color: #afafaf;
}

#contact label {
  font-size: 17px; /* Reduce font size */
  color: #fff;
  margin-bottom: 6px; /* Reduce margin */
}

#contact input#file {
  padding: 6px 0px; /* Adjust padding */
  background-color: transparent;
  border: none;
  border-radius: 0px;
}

#contact button {
  /* margin-top: 20px;  */
  /* width: 100%; */
  text-align: center;
}

.pnlm-container {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Add scrollbar for overflow */
  position: relative;
  cursor: default;
  width: 100vh;
  height: auto; /* Allows the height to adjust based on content */
  font-family: Helvetica, nimbus sans l, liberation sans, Arial, sans-serif;
  background: #f4f4f4 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NyIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCA2NyAxMDAiPgo8cGF0aCBzdHJva2U9IiNjY2MiIGZpbGw9Im5vbmUiIGQ9Ik0zMy41LDUwLDAsNjMsMzMuNSw3NSw2Nyw2MywzMy41LDUwbS0zMy41LTUwLDY3LDI1bS0wLjUsMCwwLDc1bS02Ni41LTc1LDY3LTI1bS0zMy41LDc1LDAsMjVtMC0xMDAsMCw1MCIvPgo8L3N2Zz4K) repeat;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  line-height: 1.4;
  contain: content;
  touch-action: none;
}

@media screen and (max-width: 768px) {
  .pnlm-container {
      height: 500px; /* Adjust height for tablet */
      width: 400px; /* Full width on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .pnlm-container {
      height: 500px; /* Adjust height for mobile devices */
      width: 300px; /* Full width on mobile screens */
  }
}

.simg{
  border-radius: 20px;
  height: 16em ;
  object-fit: cover
}
  

