.left-image {
    position: relative;
    display: inline-block;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50px; /* Adjust icon size as needed */
    color: white;
    background-color: rgba(68, 107, 198, 0.5); /* Semi-transparent background */
    border-radius: 50%;
    padding: 20px; /* Adjust padding for better spacing */
    cursor: pointer;
  }
  
  .play-button i {
    margin-bottom: 10px; /* Space between the icon and text */
  }
  
  .play-text {
    font-size: 18px; /* Adjust text size as needed */
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Darken background on hover */
  }
  