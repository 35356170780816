.item-details-page {
    font-family: Arial, sans-serif;
  }
  
  /* .container {
    max-width: 800px;
    margin: 0 auto;
  } */
  
  /* .section-heading {
    margin-top: 80px;
    text-align: center;
  } */

  
  .animate {
    color: #fff;
  }
  
  .line-dec {
    width: 60px;
    height: 3px;
    background-color: #fff;
    margin: 10px auto;
  }
  
  fieldset {
    border: none;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    background-color: #303245;
    border-radius: 12px;
    border: none;
    color: #242323;
    font-size: 15px;
    height: 40px;
    padding: 0 20px;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus {
    outline: none;
    background-color: #252836;
    box-shadow: 0 0 20px rgba(126, 5, 240, 0.909);
  }
  
  .orange-button {
    background-color: #7453fc;
    border: none;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 16px;
    transition: background-color 0.3s;
  }
  
  .orange-button:hover {
    background-image: linear-gradient(45deg, #7453fc, #a981f6);
    border: 1px solid #fff;
  }
  
  .error-message {
    color: #ff6f61;
  }

  .success-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .dialog-content {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
 
  .close-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #7453fc;
    border: 1px solid #7453fc;
    
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }
  .close-button:hover {
    background-image: linear-gradient(45deg, #7453fc, #a981f6);
    border: 1px solid #fff;
}